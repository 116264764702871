import React, { useState } from "react"
import loadable from "@loadable/component"
import "../../css/typography-overpass.css"
const ChatComponent = loadable(() => import("../../components/Chat Iframe"))

function Chat() {
  const [hideSendBox, setHideSendBox] = useState(true)
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <ChatComponent
      name="regioit"
      token="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzUxNTYxMzkuMDE2OTM5LCJleHAiOjIzMDU4NzYxMzkuMDE2OTM5LCJib3RJZCI6IjFjNm85OWJza3J1eGdhcTIiLCJpc3MiOiJjb252YWlzZSIsImF1ZCI6ImNvbnZhaXNlLWNvdXJpZXIifQ.pgRbdRUTDb5rmQ3FsVK4LqZxHMPTJLrrXFsiIgfJ5Hx6dS6pk4yremRDwwhjNWls480-ESOMvAgz4PYxFg2GwWtkgeJ6bIrk4hOPPNeok4SW0wYEY-yUuAst-WpFPIHwlzkaUAdFZXNQAEbXSkDe33BEWgT47Fyah1iuvWTKvpiOGI3YYF-z3i95uazJ7yGr7LdUoSn9cf--EhWbSgG0a8rJFa1wXyJTo25dRHLK1DQ1fKwzAfYzf9CRRFH-sYTtAxHISR-t8oydGBTFsrbVoIDOWEMbc6oq8ymbIEFWwm21L0Yvki_kXtjjCgxjwQz31qIo_1cMm7re_87ooHyKKztTFxrICSqp7rzNkkwJZlnB5th24_TyAgFXijlaK7ZBREqZrPeg4NUyPlB9dgC0szLq5_Lagv9MucN6GNFREYm-aXZAdFKWjDEJbz--8uLd_wVtnDdExwSHIMir-yfWghXBobIjqYf2XHBBmokofMBDCJPzbYmCKZIzBTiAqRfM1djrtsi2y2fNzPViFhSDR1eEJhK3E6sbnM-fkesb1oZ-U23gJoJveRv2irhZQr03JUZxlH0If_UhE0DirbfRFlByRK2rdJ3KI9JrmM6jp87fKtN403MOgoQXMvBiCbh7knk5S4RrhP15brU0yd2w4AELLY4_9drd1QE827-lsjE"
      botId="1c6o99bskruxgaq2"
      domain="https://courier.convaise.com/frontend/v3/directline"
      // // token="3jwgou3DF10.A35SD0t0jPu-rCUgveaj4VUNYjOyV_Afesxi5A9J02c"
      token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s" // bots-prod-01
      // token="AhaaisD-ce0.7csNC_FrUbij1tjiy_bqmh-hON-1H7nc0SA-L5RG4jE" // bot-process-dev-01
      // botId="ddy159n8l3jvyugu"
      // domain="https://europe.directline.botframework.com/v3/directline"
      webSocket={true}
      hasGDPR={false}
      onHideSendBox={value => setHideSendBox(value)}
      autoFocusVersion="v1"
      styleOptions={{
        primaryFont:
          'Overpass,sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#134d88",
        botAvatarBackgroundColor: "#fff",
        hideSendBox,
        transcriptVisualKeyboardIndicatorWidth: 1,
        transcriptActivityVisualKeyboardIndicatorWidth: 1,
        // hideSendBox: true,
        // primaryFont:
        //   'muli,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      adaptiveCardsHostConfig={{
        containerStyles: {
          emphasis: {
            backgroundColor: "#eee",
            foregroundColors: {
              default: {
                default: "#fff",
                subtle: "#484644",
              },
            },
          },
          accent: {
            backgroundColor: "rgb(199, 222, 249)",
            foregroundColors: {
              default: {
                default: "#fff",
                subtle: "#484644",
              },
            },
          },
        },
        actions: {
          actionAlignment: "stretch",
          actionsOrientation: "horizontal",
          buttonSpacing: 8,
          maxActions: 100,
          showCard: {
            actionMode: "inline",
            inlineTopMargin: 8,
          },
          spacing: "default",
        },
      }}
      overrideLocalizedStrings={{
        TEXT_INPUT_PLACEHOLDER: "Ihre Frage oder gesuchte Dienstleistung...",
      }}
      styleCustomizations={{
        "--button-blue": "#134d88",
        "--button-blue-light": "#5a82ac",
        "--button-red": "rgb(120, 125, 141)",
        "--button-red-light": "rgba(120, 125, 141, 0.8)",
      }}
      // css="https://static.convaise.com/webchat/v4/convaise-assistant-min_v4-1.css"
    />
  )
}

export default Chat
